import React from 'react'
import Link from 'gatsby-link'
import Layout from '../components/Layout'

import { FaFilePdf } from 'react-icons/fa'

import leap_facts from '../img/leap_facts.png'
import leap_pillars from '../img/leap_pillars.png'

export default class LEAPPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Grants</h1>
              <p>
                Phi Sigma Rho has been dedicated to developing the next
                generation of women engineers. Funding leadership development is
                just one way the Foundation works to achieve its mission and
                vision. Thanks in part to generous donations made to our grant
                programs, the Phi Sigma Rho Foundation has been able to give
                grants to fund educational and charitable programs to develop
                Phi Rhos into bold leaders, strong women, and supportive sisters
                in their communities!
              </p>
              <h2>Leadership and Educational Programming (LEAP) Grants</h2>
              <p>
                Leadership Education and Programming Grants impacts more sisters
                each year than any other program. These grants cover the
                educational costs of conferences, conventions, regional
                meetings, and webinars hosted by the Sorority each year.
                programs covered by the LEAP grant include speakers, panels, and
                leadership development workshops. These sessions are based on
                three pillars:{' '}
              </p>
              <p>
                A sister can become LEAP certified by attending workshops held
                at conferences, convention and regional meetings, and webinar
                sessions. These sessions are based on three pillars:
              </p>
              <img
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  display: 'block',
                  width: '80%',
                }}
                src={leap_pillars}
              />
              <h2>Engineering Development and Growth Experience Grants</h2>
              <p>
                The Engineering Development and Growth Experience (EDGE) grant
                program, through inspiration from Rosie and Alex Krob, supports
                collegiate Phi Sigma Rho members involved in hands-on science
                and engineering-based projects. These grants will support
                technical project led by sisters of Phi Sigma Rho that will
                ultimately develop their leadership, financial, and project
                management skills, with fundable programs such as building
                go-karts, robotics, rocketry, and more. Through the EDGGE Grant,
                the Phi Sigma Rho Foundation aims to create women leaders
                through hands-on experiences prior to entering their
                professional field.
              </p>
              <h2>DreamGirls Grant</h2>
              <p>
                DreamGirls Grants will provide service and leadership
                opportunities for active and alumnae organizations of Phi Sigma
                Rho to support the next generation of women in engineering
                through engineering-focused programming for middle school girls.
                These programs will be transferable to schools, Scout trips,
                community shelters, and more! Dream Girls in Action and DreamDay
                programming through the DreamGirls Initiative are fundable
                programs through this grant opportunity.
              </p>
              <p>
                Founded in 2015 by Phi Sigma Rho co-founder, Rashmi Drummond,
                the DreamGirls Initiative is a nonprofit focused on exposing
                middle schools girls to femal role models and their careers
                through a variety of hands-on events. This program is committed
                to inspiring young women in the fields of Science, Technology,
                Engineering, and Math (STEM) through day long &ldquo;Dream
                Days&rdquo; and after-school &ldquo;DreamGirls in Action&rdquo;
                programming. These programs differentiate themselves by putting
                career paths first, instead of focusing on STEM skills alone.
                The standout feature of these programs is the focus of female
                role models for participants through corporate involvement and
                hands-on programming
              </p>
              <p>
                For more information about the DreamGirls initiated visit{' '}
                <a href="https://www.dreamgirlsinitiative.com">
                  www.dreamgirlsinitiative.com
                </a>
                .
              </p>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/QuU5F7yWN3g"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
              <p>
                It&apos;s only through the financial givin of our generous
                donors that these grant programs have been made possible. We ask
                that you help us continue to support the professional
                development, personal growth, and community outreach of sisters
                of Phi Sigma Rho by giving to one of these grant programs today.
              </p>
              <br />
              <br />
              <a
                href="https://www.classy.org/give/238204/#!/donation/checkout"
                className="button is-large is-rounded"
                style={{
                  fontFamily: 'Quicksand',
                  fontWeight: 'bold',
                  minWidth: '200px',
                  display: 'block',
                  color: '#8e2344',
                  backgroundColor: 'white',
                  border: '3px #8e2344 solid',
                  borderRadius: 0,
                }}
              >
                Donate to LEAP!
              </a>
              <br />
              <p>
                <Link to="/">← Go Home</Link>
              </p>
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
